<script lang="ts" setup>
const props = defineProps<{
  name: string
}>()

provide('$fcDynamicFormField', {
  name: props.name
})
</script>

<template>
  <FormField class="flex flex-col gap-2">
    <slot />
  </FormField>
</template>
