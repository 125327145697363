<script>
import BaseInput from '@primevue/core/baseinput';

export default {
  name: 'ListInputText',
  extends: BaseInput,
  inject: {
    $pcFluid: { default: null }
  },
  props: {
    defaultValue: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    addItem(event) {
      if (!this.d_value) {
        this.writeValue([''], event)
      } else {
        this.writeValue([...this.d_value, ''], event)
      }
    },
    removeItem(index) {
      const updated = [...this.d_value]
      updated.splice(index, 1)
      this.writeValue(updated)
    }
  }
}
</script>

<template>
  <div class="flex flex-col space-y-2">
    <div
      v-for="(item, index) in d_value"
      class="flex items-center"
    >
      <InputText
        :value="item"
        fluid
        @update:model-value="(value) => {
          const updated = [...d_value]
          updated[index] = value ?? ''
          writeValue(updated, event)
        }"
      />

      <Button
        icon="pi pi-trash"
        outlined
        severity="danger"
        size="small"
        variant="text"
        @click="() => removeItem(index)"
      />
    </div>
    <div class="flex justify-start">
      <Button
        icon="pi pi-plus"
        label="Add"
        severity="secondary"
        size="small"
        @click="addItem"
      />
    </div>
  </div>
</template>