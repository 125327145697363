<script lang="ts" setup>
import { type AutoComplete, type TreeTableFilterMeta, type TreeTableFilterMetaData } from "primevue";
import { FilterMatchMode } from '@primevue/core/api';
import type {DynamicDialogCloseOptions} from "primevue/dynamicdialogoptions";

type PaginatedList = {
  /** @example 123 */
  count: number;
  /**
   * Format: uri
   * @example http://api.example.org/accounts/?page=4
   */
  next?: string | null;
  /**
   * Format: uri
   * @example http://api.example.org/accounts/?page=2
   */
  previous?: string | null;
  results: any[];
}

const props = defineProps<{
  addComponent?: Component,
  url: string,
  searchParam: string,
}>()
const dialog = useDialog()
const field = ref<typeof AutoComplete>()
const filters = ref<TreeTableFilterMeta>({
  [props.searchParam]: {value: null, matchMode: FilterMatchMode.CONTAINS},
})
const fetchQuery = computed(() => ({...getFiltersValue(filters.value)}))

const {data: objectList, status} = useApi<PaginatedList|any[]>(props.url, {
  params: fetchQuery,
  watch: [filters]
})

const suggestions = computed<any[]>(() => {
  if (!objectList.value) return []
  if (Array.isArray(objectList.value)) return objectList.value
  return objectList.value.results
})
</script>

<template>
  <div class="flex space-x-2">
    <AutoComplete
      v-bind="$attrs"
      :delay="500"
      :loading="status === 'pending'"
      :min-length="1"
      :suggestions
      class="flex-grow"
      dropdown
      force-selection
      ref="field"
      @complete="event => (filters[props.searchParam] as TreeTableFilterMetaData).value = event.query"
    />
    <Button
      v-if="props.addComponent"
      icon="pi pi-plus"
      rounded
      severity="secondary"
      variant="text"
      @click="() => dialog.open(props.addComponent, {
        props: {
          breakpoints: {
            '960px': '75vw',
            '640px': '100vw'
          },
          header: 'Add',
          modal: true,
          style: {width: '30vw'}
        },
        onClose: (opt: DynamicDialogCloseOptions) => {
          if (!opt?.data?.object || !field) return
          // @ts-ignore
          field.onOptionSelect(null, opt.data.object)
        }
      })"
    />
  </div>
</template>
