<script lang="ts" setup>
import type {FormSubmitEvent} from "@primevue/forms";
import {yupResolver} from "@primevue/forms/resolvers/yup";
import * as yup from 'yup';

declare type FormField = {
  as: string
  disabled?: boolean
  name: string
  label?: string
  schema: yup.Schema
  [key: string]: any,
}

declare type FormSubmitProps = {
  label?: string
  icon?: string
  severity?: string
}

const emits = defineEmits<{
  submit: [event: FormSubmitEvent]
}>()
const props = withDefaults(defineProps<{
  disabled?: boolean,
  loading?: boolean,
  fields: FormField[],
  submitProps?: FormSubmitProps
}>(), {
  disabled: false,
  loading: false,
})
const schemas = ref<Record<string, yup.Schema>>({})

const resolver = computed(() => yupResolver(yup.object().shape(schemas.value)))

const addField = (name: string, schema: yup.Schema) => {
  schema && (schemas.value[name] = schema)
}

provide('$fcDynamicForm', { addField, disabled: props.disabled, loading: props.loading })
</script>

<template>
  <Form
    v-slot="$form"
    :resolver
    class="space-y-5"
    @submit="(event) => emits('submit', event)"
  >
    <slot name="before" />
    <slot v-bind="$form">
      <template v-for="{name, label, ...rest} in props.fields" :key="name">
        <DynamicFormField :name="name">
          <DynamicFormLabel>{{ label }}</DynamicFormLabel>
          <DynamicFormControl v-bind="rest" :disabled="props.disabled || props.loading" />
          <DynamicFormMessage />
        </DynamicFormField>
      </template>

      <slot name="after-fields"></slot>

      <DynamicFormSubmit v-bind="props.submitProps" :disabled="props.disabled || props.loading" :loading="props.loading" />
    </slot>

    <slot name="after" />
  </Form>
</template>
