<script lang="ts" setup>
import type {DynamicFormInstance} from "~/components/DynamicForm";

const props = withDefaults(
  defineProps<{
    icon?: string,
    label?: string
    severity?: string,
  }>(),
  {
    severity: 'secondary',
    label: 'Submit'
  }
)
</script>

<template>
  <Button
    fluid
    type="submit"
    v-bind="props"
  />
</template>
