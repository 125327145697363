<script lang="ts" setup>
import * as yup from 'yup';
import * as PrimeVue from 'primevue';
import type {DynamicFormInstance} from "./DynamicForm";
import type {DynamicFormFieldInstance} from "./DynamicFormField";
import * as Fields from './fields';

const props = defineProps<{
  as: string,
  schema: yup.Schema,
}>()

const $fcDynamicForm = inject<DynamicFormInstance|undefined>('$fcDynamicForm', undefined)
const $fcDynamicFormField = inject<DynamicFormFieldInstance|undefined>('$fcDynamicFormField', undefined)

const id = computed<string>(() => `id_${$fcDynamicFormField?.name}`)
const name = computed<string>(() => $fcDynamicFormField ? $fcDynamicFormField.name : '')
// @ts-ignore
const component = computed(() => PrimeVue[props.as] ?? Fields[props.as])

$fcDynamicForm?.addField(name.value, props.schema)
</script>

<template>
  <component
    :id="id"
    :is="component"
    :name="name"
    fluid
  />
</template>
