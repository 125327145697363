<script lang="ts" setup>
import type {DynamicFormFieldInstance} from "./DynamicFormField";

const $fcDynamicFormField = inject<DynamicFormFieldInstance|undefined>('$fcDynamicFormField', undefined)
const htmlFor = computed<string>(() => `id_${$fcDynamicFormField?.name}`)
</script>

<template>
  <label :for="htmlFor"><slot /></label>
</template>
