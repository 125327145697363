<script lang="ts" setup>
import type {useFormReturn} from '@primevue/forms/useform';
import type {DynamicFormFieldInstance} from "~/components/DynamicFormField";

const $pcForm = inject<useFormReturn|undefined>('$pcForm', undefined);
const $fcDynamicFormField = inject<DynamicFormFieldInstance|undefined>('$fcDynamicFormField', undefined);

const fieldName = computed<string>(() => $fcDynamicFormField ? $fcDynamicFormField.name : '');
const state = computed(() => $pcForm?.states?.[fieldName.value]);
const errors = computed(() => state.value?.errors);
const invalid = computed(() => state.value?.invalid);
</script>

<template>
  <Message v-if="invalid" severity="error" size="small" variant="simple">
    <slot>
      <span v-for="(message, index) in errors" :key="index" class="block">{{ message }}</span>
    </slot>
  </Message>
</template>
